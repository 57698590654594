<script>
import request from '@/api/request'
import global from '@/api/global'
import CoursePackage from '@/models/cource_package/index'
import store from '@/store'
import { mapState } from 'vuex'
import CheckCrousePackage from '@/components/resource_library/course_package/check/index'
import { listConvertToModel } from '@/models'
import CoursePackageComponent from '@/views/fun_template/course_package'
import CoursePackageThumbnail from '@/views/fun_template/resource/course_center/course_thumbnail'
import CoursePackageTask from '@/views/ShoppingMail/Task/Details'
export default {
  components: {
    CheckCrousePackage,
    CoursePackageComponent,
    CoursePackageThumbnail,
    CoursePackageTask,
  },
  props: {
    page: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  // inject: [
  //   'page', // 这里为什么一直获取的都是undefined
  //   'totalCount'
  // ],
  data () {
    return {
      filterType: 0,
      loading: false,
      currentCoursePackage: undefined,
      enterpriseList: undefined,
    }
  },
  computed: {
    ...mapState({
      coursePackage: state => state.course_package.data,
      coursePackageTask: state => state.course_package.task,
      coursePackageList: state => state.course_package.packageList,
    }),
    showNavigation () {
      const {
        coursePackage,
        coursePackageTask,
      } = this
      return coursePackage || coursePackageTask
    },
    showEmpty () {
      const {
        coursePackageList,
        coursePackage,
        coursePackageTask,
      } = this
      if (!coursePackage && !coursePackageTask && (!coursePackageList || coursePackageList.length === 0)) {
        return true
      }
      return false
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    async init() {
      // await this.getCoursePackage()
      this.getList()
      // this.getEnterpriseList()
    },
    async getEnterpriseList () {
      const res = await global.doPost({
        url: '/resource/ownedEnterprisesList',
      })
      console.log(res)
      this.$set(
        this,
        'enterpriseList',
        res.contents.page.result
      )
    },
    async getList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await store.dispatch('getCoursePackageList')
      this.loading = false
      if (res.message === 'success') {

        this.$emit(
          'changePage',
          res.contents.page
        )
      }
      return
      // const res = await request.request(
      //   '/resource/courceList'
      // )
      // console.log(res)
      const resp = await global.doPost({
        url:'/resource/courceList',
      })
      if (res.message === 'success') {
        const list = listConvertToModel({
          list: res.contents.page.result,
          model: CoursePackage,
        })
        store.commit('setCoursePackageList', list)

        this.$emit(
          'changePage',
          res.contents.page
        )
      }
    },
    async getCoursePackage (id) {
      if (this.loading) {
        return
      }
      this.loading = true

      const res = await request.request(
        '/resource/queryCource',
        {
          courceId: id
        }
      )
      this.loading = false
      if (res.message === 'success') {
        store.commit('setCoursePackageData', new CoursePackage(res.contents.PCource))

        // this.coursePackage.updateData(res.contents.PCource)
        // let coursePackage = new CoursePackage(res.contents.PCource)
        // store.commit('setCoursePackageData', coursePackage)
      }
    },
    clickCoursePackage (item) {
      console.log(item)
      // this.$set(
      //   this,
      //   'currentCoursePackage',
      //   item
      // )
      if (item) {
        this.getCoursePackage(item.id)
      } else {
        store.commit('setCoursePackageData', undefined)
        store.commit('setCoursePackageTask', undefined)
      }
    },
    touchCourseName () {
      store.commit('setCoursePackageTask', undefined)
    }
  }

}
</script>

<template>
<div
  v-loading="loading"
  class="v_fun_template_resource_course_center_index d-flex flex-column bbox_wrapper"
>
  <div
    v-show="!coursePackage"
    class="filter_group d-flex align-center flex-shrink-0"
  >
    <div
      class="tag_group d-flex align-center on"
    >
      <div
        class="tag"
      >
        最新
      </div>
    </div>
    <div
      class="tag_group d-flex align-center"
    >
      <div
        class="icon hot"
      ></div>
      <div
        class="tag"
      >
        热门
      </div>
    </div>
    <div
      v-if="page"
      class="course_number"
    >
      共计{{ page.totalCount }}门课程
    </div>
  </div>
  <div
    v-show="showNavigation"
    class="navigation_group d-flex align-center flex-shrink-0 sticky_group"
  >
    <div
      class="tag cp"
      @click="clickCoursePackage(undefined)"
    >
      课程中心
    </div>
    <div
      class="text"
    >
      >
    </div>
    <div
      class="tag"
      :class="{
        on: coursePackage && !coursePackageTask,
        cp: coursePackageTask
      }"
      @click="touchCourseName(undefined)"
    >
      {{ coursePackage && coursePackage.title }}
    </div>
    <div
      v-if="coursePackageTask"
      class="text"
    >
      >
    </div>
    <div
      v-if="coursePackageTask"
      class="tag on"
    >
      {{ coursePackageTask && coursePackageTask.name }}
    </div>
  </div>
  <!--{{ coursePackage }}-->
  <div
    class="course_list_wrapper"
  >
    <CheckCrousePackage
      class="course_package_detail_wrapper"
      v-if="coursePackage && !coursePackageTask"
      :show-top="false"
      :audit-mode="false"
      :view-mode="true"
      :alert-task="false"
    ></CheckCrousePackage>
    <CoursePackageTask
      v-if="coursePackageTask"
      :task_id="coursePackageTask.id"
      :show-pay="true"
      :free-to-buy="true"
    ></CoursePackageTask>
    <div
      v-if="coursePackageList && !coursePackage"
      class="course_list d-flex align-center flex-wrap"
    >
      <template
        v-for="(item, index) in coursePackageList"
      >
        <CoursePackageThumbnail
          :key="index"
          :course-package="item"
          @clickCoursePackage="clickCoursePackage"
        ></CoursePackageThumbnail>
        <div
          v-if="0"
          :key="index"
          class="course_wrapper cp"
          @click="clickCoursePackage(item)"
        >
          <div
            class="img_group"
          >
            <div
              class="img"
              :style="{
                backgroundImage: `url(${ $file_url }${ item.webpic })`
              }"
            ></div>
            <div
              class="img img_url"
              :style="{
                backgroundImage: `url(${ $img_url }${ item.webpic })`
              }"
            ></div>
          </div>
          <div
            class="info_group"
          >
            <div
              class="name"
            >
              {{ item.title }}
            </div>
            <div
              class="info"
            >
              {{ item.ownedEnterprises }}｜{{ item.userName }}
            </div>
          </div>
          <div
            class="fun_group d-flex align-center justify-end"
          >
            <div
              class="icon user"
            ></div>
            <div
              class="num"
            >
              {{ item.studyCount }}
            </div>
            <div
              class="icon zan"
            ></div>
            <div
              class="num"
            >
              {{ item.likeCount }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="showEmpty"
      class="empty_wrapper d-flex align-center justify-center"
    >
      <div
        class="empty"
      >
        暂无内容
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_fun_template_resource_course_center_index {
  width: 100%;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  padding: 20px 0px;
  position: relative;
  .filter_group {
    width: 100%;
    padding: {
      left: 40px;
      right: 40px;
    }
    .tag_group {
      margin-right: 12px;
      padding: 0 16px;
      height: 28px;
      background-color: rgba(0,0,0,0.05);
      border-radius: 4px;
      .tag {
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        text-align: left;
        font-style: normal;
      }
      .icon {
        @include background;
        width: 10px;
        height: 12px;
        margin-right: 3px;
        &.hot {
          background-image: url(~@/assets/images/resource/course_center_filter_hot.png);
        }
      }
      &.on {
        background-color: rgba(0, 84, 167, 0.1);
        &.tag {
          color: $main_blue;
        }
      }
    }
    .course_number {
      width: 103px;
      height: 19px;
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      opacity: 0.5;
    }
  }
  .navigation_group {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    padding: 10px 40px;
    font-size: 14px;
    color: #666666;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    z-index: 9;
    .tag {
      @include transition;
      &.on {
        color: $main_blue;
      }
    }
    .text {
      margin: 0 4px;
    }
  }
  .course_list_wrapper {
    width: 100%;
    height: 100%;
    padding: {
      left: 40px;
      right: 40px;
    }
    //overflow: auto;
    .course_package_detail_wrapper {
      padding-top: 20px;
    }
    .course_list {
      padding-bottom: 16px;
      .course_wrapper {
        @include transition;
        width: 288px;
        height: 280px;
        background: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
        border-radius: 16px;
        border: 1px solid #E8EEFF;
        margin: {
          right: 16px;
          top: 16px;
        }
        &:hover {
          box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
        }
        .img_group {
          width: 288px;
          height: 162px;
          border-radius: 16px 16px 0 0;
          overflow: hidden;
          position: relative;
          .img {
            @include background;
            width: 100%;
            height: 100%;
            background-size: cover;
            z-index: 2;
            &.img_url {
              position: absolute;
              z-index: 3;
              top: 0;
              left: 0;
            }
          }
        }
        .info_group {
          width: 100%;
          height: 74px;
          padding: 0 16px;
          border-bottom: 2px solid #F8F8F8;
          .name {
            @include single_line_intercept;
            padding-top: 16px;
            padding-bottom: 4px;
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            line-height: 22px;
            text-align: justify;
            font-style: normal;
            text-transform: uppercase;
          }
          .info {
            @include single_line_intercept;
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
          }
        }
        .fun_group {
          height: 40px;
          width: 100%;
          padding: 0 16px;
          .icon {
            width: 14px;
            height: 14px;
            margin-right: 5px;
            margin-left: 16px;
            &.user {
              background-image: url(~@/assets/images/resource/course_user.png);
            }
            &.zan {
              background-image: url(~@/assets/images/resource/course_zan.png);
            }
          }
          .num {
            font-weight: 400;
            font-size: 12px;
            color: #AAAAAA;
            line-height: 17px;
            text-align: right;
            font-style: normal;
          }
        }
      }
    }
    .empty_wrapper {
      width: 100%;
      height: 100%;
      .empty {
        font-size: 14px;
        color: #666666;
      }
    }
  }
}
</style>
