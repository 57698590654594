<script>
import { mapState } from 'vuex'
import store from '@/store'
import ResourceCenterDetail from '@/views/fun_template/resource/resource_center/detail'
import FileThumbnail from '@/views/fun_template/resource/resource_center/file_thumbnail'

export default {
  components: {
    ResourceCenterDetail,
    FileThumbnail,
  },
  data () {
    return {
      loading: false,
    }
  },
  props: {
    page: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  // inject: [
  //   'page',
  // ],
  computed: {
    ...mapState({
      resourceFileList: state => state.resource.fileList,
      resourceFileData: state => state.resource.fileData,
      resourceFileListOfTaskList: state => state.resource.fileListOfTaskList,
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.getLineNumber()
      await this.getFileList()
    },
    async getFileList () {
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await store.dispatch('getResourceFileList')
      this.loading = false
      if (res.message === 'success') {
        this.$emit(
          'changePage',
          res.contents.page
        )
      }
    },
    viewFile (data) {
      store.commit(
        'setResourceFileData',
        data
      )
    },
    // 通过list_group的宽度 获取每行可以显示素材的数量
    getLineNumber () {
      const listGroupRef = this.$refs.list_group
      // 获取listGroupRef的宽度 padding左右各40
      const width = listGroupRef.offsetWidth - 80
      // 每个素材的宽度为346px 计算能显示多少个素材
      const lineNumber = Math.floor(width / 346)
      store.commit('setResourceTaskCount', lineNumber)
    }
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="v_resource_resource_center_index"
>
  <div
    v-if="!resourceFileData"
    class="filter_group d-flex align-center flex-shrink-0"
  >
    <div
      class="tag_group d-flex align-center on"
    >
      <div
        class="tag"
      >
        最新
      </div>
    </div>
    <div
      class="tag_group d-flex align-center"
    >
      <div
        class="icon hot"
      ></div>
      <div
        class="tag"
      >
        热门
      </div>
    </div>
    <div
      v-if="page"
      class="course_number"
    >
      共计{{ page.totalCount }}个素材
    </div>
  </div>
  <div
    v-if="resourceFileData"
    class="navigation_group d-flex align-center flex-shrink-0"
  >
    <div
      class="tag cp"
      @click="viewFile(undefined)"
    >
      资源中心
    </div>
    <div
      class="text"
    >
      >
    </div>
    <div
      class="tag on"
    >
      {{ resourceFileData && (resourceFileData.fileName || resourceFileData.name) }}
    </div>
  </div>
  <div
    class="content_wrapper"
  >
    <ResourceCenterDetail
      v-if="resourceFileData"
    ></ResourceCenterDetail>
    <div
      v-if="!resourceFileData"
      class="resource_list_wrapper"
    >
      <div
        class="list_group d-flex flex-wrap align-start"
        ref="list_group"
      >
        <template
          v-for="(item, index) in resourceFileListOfTaskList"
        >
          <FileThumbnail
            :key="`task${ index }`"
            :file-data="item"
            @viewFile="viewFile"
          ></FileThumbnail>
        </template>

        <template
          v-for="(item, index) in resourceFileList"
        >
          <FileThumbnail
            :key="index"
            :file-data="item"
            @viewFile="viewFile"
          ></FileThumbnail>
          <div
            v-if="0"
            :key="index"
            class="resource_group d-flex align-center"
            @click="viewFile(item)"
          >
            <div
              class="icon_group flex-shrink-0"
            >
              <div
                class="icon"
                :class="{
                  [item.fileType]: true
                }"
              ></div>
            </div>
            <div
              class="info_group"
            >
              <div
                class="name"
              >
                {{ item.fileName }}
              </div>
              <div
                class="detail_group d-flex align-center justify-space-between"
              >
                <div
                  class="detail"
                >
                  {{ item.userName }} | {{ item.ownedEnterprises }}
                </div>
                <div
                  class="number_group d-flex align-center"
                >
                  <div
                    class="icon"
                  ></div>
                  <div
                    class="number"
                  >{{ '2.3w '}}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
@mixin type($type) {
  &.#{$type} {
    background-image: url(~@/assets/images/resource/#{$type}.png);
  }
}
.v_resource_resource_center_index {
  width: 100%;
  overflow: auto;
  height: 100%;
  padding: 20px 0;
  background-color: #fff;
  .filter_group {
    width: 100%;
    //background-color: #fff;
    padding: {
      left: 40px;
      right: 40px;
    }
    .tag_group {
      margin-right: 12px;
      padding: 0 16px;
      height: 28px;
      background-color: rgba(0,0,0,0.05);
      border-radius: 4px;
      .tag {
        font-size: 14px;
        color: #333333;
        line-height: 19px;
        text-align: left;
        font-style: normal;
      }
      .icon {
        @include background;
        width: 10px;
        height: 12px;
        margin-right: 3px;
        &.hot {
          background-image: url(~@/assets/images/resource/course_center_filter_hot.png);
        }
      }
      &.on {
        background-color: rgba(0, 84, 167, 0.1);
        &.tag {
          color: $main_blue;
        }
      }
    }
    .course_number {
      width: 103px;
      height: 19px;
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      opacity: 0.5;
    }
  }
  .navigation_group {
    width: 100%;
    padding: 0 40px 10px;
    font-size: 14px;
    color: #666666;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    .tag {
      @include transition;
      &.on {
        color: $main_blue;
      }
    }
    .text {
      padding: 0 5px;
    }
  }
  .content_wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;
    .resource_list_wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;
      .list_group {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: {
          left: 40px;
          right: 40px;
        }
        .resource_group {
          width: 330px;
          height: 64px;
          padding: 0 16px;
          background: #FFFFFF;
          box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
          border-radius: 10px;
          border: 1px solid #E8EEFF;
          margin: {
            top: 10px;
            right: 16px;
            bottom: 16px;
          }
          .icon_group {
            margin-right: 10px;
            .icon {
              @include background;
              @include type(zip);
              @include type(excel);
              @include type(video);
              @include type(pdf);
              @include type(pic);
              @include type(ppt);
              @include type(txt);
              @include type(unknown);
              @include type(word);
              @include type(vr);
              @include type(audio);
              @include type(task);
              @include type(link);
              width: 32px;
              height: 32px;
            }
          }
          .info_group {
            overflow: auto;
            .name {
              @include single_line_intercept;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              text-align: left;
              font-style: normal;
            }
            .detail_group {
              padding-top: 5px;
              .detail {
                @include single_line_intercept;
                font-weight: 400;
                font-size: 12px;
                color: #9F9F9F;
                text-align: left;
                font-style: normal;
              }
              .number_group {
                padding-left: 10px;
                .icon {
                  background-image: url(~@/assets/images/resource/center_of_resource_review.png);
                  width: 20px;
                  height: 14px;
                }
                .number {
                  padding-left: 3px;
                  font-size: 12px;
                  color: #666666;
                  text-align: left;
                  font-style: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
