<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      courcetypeList: [
        { name: '标准课', value: 1 },
        { name: '实践课', value: 2 },
        { name: '实践任务', value: 99 },
        { name: '专业群课', value: 3 },
        { name: '仿真', value: 4 },
        { name: '思政', value: 5 },
        { name: '双创融合', value: 6 },
        { name: '培训课', value: 7 },
        { name: '其他', value: 8 },
      ],
      // courcetype: '',
      filetypeList: [
        { name: '任务类', value: 99 },
        { name: '视频类', value: 1 },
        { name: '文本类', value: 2 },
        { name: 'PPT演示文稿', value: 3 },
        { name: 'PDF', value: 4 },
        { name: '图形图像类', value: 5 },
        { name: '音频类', value: 6 },
        { name: '虚拟仿真类', value: 7 },
        { name: '表格类', value: 8 },
        { name: '网页链接', value: 9 },
        { name: '其他', value: 10 },
      ],
      // filetype: '',
    }
  },
  computed: {
    ...mapState({
      courcetype: state => state.resource.fileCourceType,
      filetype: state => state.resource.filetype,
      // coursePackage: state => state.course_package.data,
      // coursePackageTask: state => state.course_package.task,
      // coursePackageList: state => state.course_package.packageList,
    }),
  },
  methods: {
    async changeType (type) {
      if (this.courcetype === type) {
        return
      }
      // this.courcetype = type
      store.commit(
        'setResourceFileCourceType',
        type
      )
      this.$emit('changePageLoading', true)
      // store.commit('setCoursePackageData', undefined)
      const res = await store.dispatch(
        'getResourceFileList',
        {
          // courcetype: this.courcetype,
          filetype: this.filetype
        }
      )
      this.$emit('changePageLoading', false)
      if (res.message === 'success') {
        this.$emit(
          'changePage',
          res.contents.page
        )
      }
    },
    async changeFileType (type) {
      if (this.filetype === type) {
        return
      }
      // this.filetype = type
      store.commit(
        'setResourceFileType',
        type
      )
      this.$emit('changePageLoading', true)
      const res = await store.dispatch(
        'getResourceFileList',
        {
          // courcetype: this.courcetype,
          filetype: this.filetype
        }
      )
      this.$emit('changePageLoading', false)
      if (res.message === 'success') {
        this.$emit(
          'changePage',
          res.contents.page
        )
      }
      console.log(res.contents.page)
    },
  }
}
</script>

<template>
<div
  class="v_resource_filter_group bbox_wrapper flex-shrink-0"
>
  <div
    class="filter_wrapper"
  >
    <div
      class="line_group d-flex align-stretch"
    >
      <div
        class="key_group flex-shrink-0"
      >
        <div
          class="key"
        >
          媒体类型:
        </div>
      </div>
      <div
        class="value_group d-flex align-start"
      >
        <div
          class="list_group d-flex align-start flex-wrap"
        >
          <div
            class="tag cp"
            :class="{
              on: filetype === ''
            }"
            @click="changeFileType('')"
          >
            全部
          </div>
          <template
            v-for="(item, index) in filetypeList"
          >
            <div
              :key="index"
              class="tag cp"
              :class="{
                on: filetype === item.value
              }"
              @click="changeFileType(item.value)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
        <div
          v-if="0"
          class="more flex-shrink-0"
        >
          更多
        </div>
      </div>
    </div>
    <div
      class="line_group d-flex align-stretch"
    >
      <div
        class="key_group flex-shrink-0"
      >
        <div
          class="key"
        >
          应用类型:
        </div>
      </div>
      <div
        class="value_group d-flex align-start"
      >
        <div
          class="list_group d-flex align-start flex-wrap"
        >
          <div
            class="tag cp"
            :class="{
              on: courcetype === ''
            }"
            @click="changeType('')"
          >
            全部
          </div>
          <template
            v-for="(item, index) in courcetypeList"
          >
            <div
              :key="index"
              class="tag cp"
              :class="{
                on: courcetype === item.value
              }"
              @click="changeType(item.value)"
            >
              {{ item.name }}
            </div>
          </template>
        </div>
        <div
          v-if="0"
          class="more flex-shrink-0"
        >
          更多
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_resource_filter_group {
  width: 100%;
  background-color: #fff;
  padding: 10px 40px;
  overflow: auto;
  .filter_wrapper {
    border-top: 1px solid #eee;
    width: 100%;
    border-bottom: 1px solid #eee;
    .line_group {
      width: 100%;
      border: {
        top: 1px solid #eee;
        left: 1px solid #eee;
        right: 1px solid #eee;
      }
      .key_group {
        background: #F6F6F6;
        padding: 5px 17px;
        .key {
          width: 71px;
          font-size: 14px;
          color: #333333;
          line-height: 28px;
          text-align: justify;
          font-style: normal;
          text-align-last: justify;
        }
      }
      .value_group {
        padding: 5px 20px;
        .list_group {
          .tag {
            flex-shrink: 0;
            font-size: 14px;
            color: #333333;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            opacity: 0.7;
            padding-right: 30px;
            &.on {
              color: $main_blue;
            }
          }
        }

        .more {
          flex-shrink: 0;
          font-size: 14px;
          color: #333333;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
