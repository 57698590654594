var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass:
        "v_fun_template_resource_course_center_index d-flex flex-column bbox_wrapper",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.coursePackage,
              expression: "!coursePackage",
            },
          ],
          staticClass: "filter_group d-flex align-center flex-shrink-0",
        },
        [
          _vm._m(0),
          _vm._m(1),
          _vm.page
            ? _c("div", { staticClass: "course_number" }, [
                _vm._v(
                  "\n      共计" + _vm._s(_vm.page.totalCount) + "门课程\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showNavigation,
              expression: "showNavigation",
            },
          ],
          staticClass:
            "navigation_group d-flex align-center flex-shrink-0 sticky_group",
        },
        [
          _c(
            "div",
            {
              staticClass: "tag cp",
              on: {
                click: function ($event) {
                  return _vm.clickCoursePackage(undefined)
                },
              },
            },
            [_vm._v("\n      课程中心\n    ")]
          ),
          _c("div", { staticClass: "text" }, [_vm._v("\n      >\n    ")]),
          _c(
            "div",
            {
              staticClass: "tag",
              class: {
                on: _vm.coursePackage && !_vm.coursePackageTask,
                cp: _vm.coursePackageTask,
              },
              on: {
                click: function ($event) {
                  return _vm.touchCourseName(undefined)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.coursePackage && _vm.coursePackage.title) +
                  "\n    "
              ),
            ]
          ),
          _vm.coursePackageTask
            ? _c("div", { staticClass: "text" }, [_vm._v("\n      >\n    ")])
            : _vm._e(),
          _vm.coursePackageTask
            ? _c("div", { staticClass: "tag on" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.coursePackageTask && _vm.coursePackageTask.name
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "course_list_wrapper" },
        [
          _vm.coursePackage && !_vm.coursePackageTask
            ? _c("CheckCrousePackage", {
                staticClass: "course_package_detail_wrapper",
                attrs: {
                  "show-top": false,
                  "audit-mode": false,
                  "view-mode": true,
                  "alert-task": false,
                },
              })
            : _vm._e(),
          _vm.coursePackageTask
            ? _c("CoursePackageTask", {
                attrs: {
                  task_id: _vm.coursePackageTask.id,
                  "show-pay": true,
                  "free-to-buy": true,
                },
              })
            : _vm._e(),
          _vm.coursePackageList && !_vm.coursePackage
            ? _c(
                "div",
                { staticClass: "course_list d-flex align-center flex-wrap" },
                [
                  _vm._l(_vm.coursePackageList, function (item, index) {
                    return [
                      _c("CoursePackageThumbnail", {
                        key: index,
                        attrs: { "course-package": item },
                        on: { clickCoursePackage: _vm.clickCoursePackage },
                      }),
                      0
                        ? _c(
                            "div",
                            {
                              key: index,
                              staticClass: "course_wrapper cp",
                              on: {
                                click: function ($event) {
                                  return _vm.clickCoursePackage(item)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "img_group" }, [
                                _c("div", {
                                  staticClass: "img",
                                  style: {
                                    backgroundImage: `url(${_vm.$file_url}${item.webpic})`,
                                  },
                                }),
                                _c("div", {
                                  staticClass: "img img_url",
                                  style: {
                                    backgroundImage: `url(${_vm.$img_url}${item.webpic})`,
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "info_group" }, [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.title) +
                                      "\n            "
                                  ),
                                ]),
                                _c("div", { staticClass: "info" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.ownedEnterprises) +
                                      "｜" +
                                      _vm._s(item.userName) +
                                      "\n            "
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "fun_group d-flex align-center justify-end",
                                },
                                [
                                  _c("div", { staticClass: "icon user" }),
                                  _c("div", { staticClass: "num" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.studyCount) +
                                        "\n            "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "icon zan" }),
                                  _c("div", { staticClass: "num" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.likeCount) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.showEmpty
            ? _c(
                "div",
                {
                  staticClass:
                    "empty_wrapper d-flex align-center justify-center",
                },
                [
                  _c("div", { staticClass: "empty" }, [
                    _vm._v("\n        暂无内容\n      "),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tag_group d-flex align-center on" }, [
      _c("div", { staticClass: "tag" }, [_vm._v("\n        最新\n      ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tag_group d-flex align-center" }, [
      _c("div", { staticClass: "icon hot" }),
      _c("div", { staticClass: "tag" }, [_vm._v("\n        热门\n      ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }